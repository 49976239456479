(function($){

    if(typeof acf === 'undefined')
        return;

    //console.log('JS admin ACF script');


})(jQuery);

